const moduleCoupons = {
  state: () => ({
    SingleCoupon: null,
    CouponStatistics: null,
    CouponUsers: null,
  }),
  actions: {
    async getSingleCoupon(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}`)
          .then((response) => {
            context.commit("setSingleCoupon", response.data.data);
          })
          .catch((_error) => {
            throw _error.message;
          });
      } catch (e) {
        throw e;
        console.log("error", e);
      }
    },
    async getCouponStatistics(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}/statistics`)
          .then((response) => {
            context.commit("setCouponStatistics", response.data.data);
          })
          .catch((_error) => {
            throw _error.message;
          });
      } catch (e) {
        throw e;
      }
    },
    async getCouponUsers(context, payload) {
      try {
        await this.$axios
          .get(`/coupons/${payload.slug}/users`,{
            params: {
              per_page: payload.perPage,
              page: payload.page,
            }
          })
          .then((response) => {
            context.commit("setCouponUsers", response.data.data);
          })
          .catch((_error) => {
            throw _error.message;
          });
      } catch (e) {
        throw e;
      }
    },

  },
  mutations: {
    setSingleCoupon(state, payload) {
      state.SingleCoupon = payload;
    },
    setCouponStatistics(state, payload) {
      state.CouponStatistics = payload;
    },
    setCouponUsers(state, payload) {
      state.CouponUsers = payload;
    },
  },
  getters: {},
};

export default moduleCoupons;
