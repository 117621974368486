import ar from "vuetify/es5/locale/ar";
export default {
  ...ar,
  pages: {
    home: "الرئيسية",
    homeDesc:
      "منصة رائدة في تقديم البيانات والأدوات المالية ، مرخصة من @tadawul .. في منصة محلل نسهل عليك اتخاذ قراراتك الاستثمارية",
    marketSum: "ملخص السوق",
    sectors: "القطاعات",
    distributions: "التوزيعات النقدية",
    subscription: "الاشتراكات",
    upgradeSubscription: "ترقية الباقة",
    blog: "المقالة",
    blogs: "المقالات",
    mohalelTools: "الأدوات ",
    tools: "أدوات ",
    discover: "مستكشف محلل",
    cashDistribution: "التوزيعات النقدية",
    comparison: "مقارنة",
    comparisons: "مقارنات",
    calendar: "الأحداث القادمة",
    profits: "نتائج الارباح",
    profile: "الملف الشخصي",
    faqs: "الأسئلة الأكثر شيوعا",
    resetPassword: "إعادة تعيين كلمة المرور",
    changPassword: "تغيير كلمة المرور",
    manageSub: "إدارة الاشتراكات",
    settings: "إعدادات الموقع",
    features: "مميزاتنا",
    categories: "التصنيفات",
    about: "عن الشركة",
    contact: "اتصل بنا",
    help: "تحتاج مساعدة ؟ ",
    search: "بحث",
    searchCompany: "ابحث باسم الشركة او الرمز",
    login: " الدخول",
    loginComplete: "تسجيل الدخول",
    signup: "تسجيل  ",
    freeAccount: "تسجيل حساب مجاني",
    signupComplete: "تسجيل حساب",
    ourVision: "رؤيتنا",
    terms: "الشروط و الأحكام",
    privacy: "سياسية الخصوصية",
    dashboard: "لوحة التحكم ",
    verification: "كود التأكيد",
    accountManagement: "إدارة الحساب",
    subscriptionManagement: "إدارة الإشتراك",
    transactionHistory: "سجل المعاملات",
    siteSettings: "إعدادات الموقع",
    personalInfo: "البيانات الشخصية",
    changeProfilePicture: "تغيير الصورة الشخصية",
    myShares: "أسهمي",
    walletsManagement: "إدارة المحافظ",
    favorites: "قائمة المتابعة",
    comparisons: "مقارنات",
    createWallet: "إنشاء محفظة",
    invoiceManagement: "الفاتورة",
    couponStatistics: "احصائيات الكوبون",
    thanks: "شكرا",
    news: "الأخبار",
    welcome: "مرحباً",
  },
  home: {
    getStarted: "إبدأ",
    enterEmailAddress: "أدخل البريد الإلكترونى",
    analytics: "تحليلات",
    whyMohlel: "لماذا محلل؟",
    subscription_subtitle: "باقات اشتراك متنوعة تناسب أهدافك",
    testimonials: "قالوا عن مُحلل",
    testimonials_subtitle:
      "أكثر من 10,000 عميل اختاروا محلل لمتابعة أسهمهم في السوق",
    consult: "اسألنى",
    consult_msg: "موجودون يوميًا للرد على استفساراتك وتساؤلاتك",
    market: {
      dividend: "توزيعات الأرباح",
      courses: "الدورات التدريبية",
      news: "الأخبار",
      QA: "سؤال وجواب",
    },
    mohlel: {
      aboutUs: "من نحن",
      plans: "الباقات",
      terms_conditions: "الشروط والأحكام",
      affiliate_marketing_program: "برنامج التسويق بالعمولة",
    },
    intro: {
      title: "اتخذ قرارات استثمارية أكثر ذكاءً ومعتمدة على البيانات",
      subtitle:
        " نحن نعمل على توفير أهم البيانات والأدوات الماليةالتي تساعدك في إتخاذ قرارات استثمارية صحيحة",
    },
  },
  account: {
    profilePictureHint:
      "تأكد من أن الصورة الشخصية التي تريد رفعها هي ملف صورة بصيغة JPG أو PNG و بحجم أقل من 5MB",
    profileWelcomeMsg:
      "أهلاً وسهلاً بك في صفحة تعديل الملف الشخصي. نأمل أن تتمكن من العثور على كل ما تريده هنا وتحسين الملف الشخصي الخاص بك.",
    passwordHintTitle: "تعليمات كلمة المرور",
    passwordHintContent: `يجب أن تكون كلمة المرور الجديدة معقدة وتشمل الأحرف الصغيرة والكبيرة، الأرقام، والحروف الخاصة.</br> أن تحتوي على أكثر من 8 حروف أو أرقام، تجنب الأسماء الشخصية، الأسماء الشعبية، التاريخ الذكي، أو الجزء الثاني من البريد الإلكتروني.`,
    currentPlan: "خطتك الحالية هى: ",
    subscriptionEnd: "ينتهي الاشتراك في ",
  },
  comparisons: {
    compareTillFive: "قارن حتى 5 شركات",
    stateShare: "حالة السهم",
  },
  labels: {
    price_difference: "فرق سعر الباقتين سنويا",
    remaining_cost: "الفائض من الباقة الحالية",
    total_net: "صافي المبلغ",
    discount: "خصم",
    reset: "حذف الفلتر",
    for: "مقابل",
    to: "إلى",
    here: "هنا",
    plan: "الباقة",
    pay: "إدفع",
    getMohlel: "احصل على باقة محلل",
    advanced: " متقدم",
    professional: " محترف",
    unlimitedAccess: "وصول غير محدود لجميع المؤشرات والبيانات",
    monthlyPlan: "الخطة الشهرية",
    annualPlan: "الخطة السنوية",
    no: "محدود",
    yes: "غير محدود",
    all: "الكل",
    limited: "محدود",
    mohlel: "محلل",
    mohlel_plan: "باقة",
    recommended: "نوصي بها",
    free: "مجانًا",
    SR_month: " ر.س/شهر",
    SR_year: "ر.س/سنة",
    SAR: "ر.س",
    MSAR: "م. (ر.س)",
    million: " م",
    SR: "ريال",
    prev: "السابق",
    next: "التالى",
    readMore: "اقرأ المزيد",
    mainMarket: "السوق الرئيسية",
    more: "المزيد",
    moreDetails: "تفاصيل اكثر",
    seeAll: "مشاهدة الكل ",
    send: "ارسال",
    verify: "تحقق",
    viewDetails: "مشاهدة التفاصيل",
    filter: "تنقية",
    sections: "قسم",
    items: "عنصر",
    mailSeller: "مراسلة البائع",
    sendRequest: "ارسال طلب",
    contactSeller: "اتصل بالبائع",
    requestOrders: "ارسل طلب ",
    description: "الوصف",
    specification: "الخصائص",
    verify: "تأكيد",
    or: "أو",
    from: "من",
    to: "الى",
    saveChanges: "حفظ التغييرات",
    monthly: "الشهرية",
    yearly: "السنوية",
    quarter: "ربع سنوي",
    half: "نصف سنوي",
    logout: "تسجيل الخروج",
    followBlog: "متابعة المقالات",
    chooseSector: "اختر القطاع",
    sortBy: "ترتيب حسب",
    choose: "اختر",
    IPOs: "الاكتتابات",
    profits: "نتائج الأرباح",
    cache: "التوزيعات النقدية",
    nextIPOs: "الأحداث القادمة",
    prevIPOs: "الأحداث السابقة",
    exportExcel: "تصدير EXCEL",
    unsubscribe: "إلغاء الاشتراك",
    upgradePlan: "ترقية الباقة",
    upgradeText: "قم بترقية حسابك وتمتع بالعديد من المزايا",
    sortByProfits: "ترتيب حسب الاعلى ربحية",
    remainingDays: "متبقي لك ",
    tillEnd: " يوم حتى تنتهي صلاحيات باقتك ",
    cancelDate: "تاريخ الغاء الاشتراك",
    subToNews: "اشترك فى النشرة الاخبارية ",
    subscribe: "اشترك",
    welcome: "مرحبا بك في منصة مُحلل ",
    slogan: "تحليل البيانات .. بداية الإستثمار الناجح ",
    upgradeAccount: "ترقية الحساب",
    total: "اجمالي المبلغ ",
    summary: "ملخص عملية الدفع",
    tax: "ضريبة القيمة المضافة (15%)",
    accept_conditions: "أوافق على",
    upgradePageSubTitle:
      " احصل على المزيد من الميزات والمحتوى مع الخطة المميزة .. ",
    choosePlan: "اختر باقتك المفضلة ",
    planPeriod: "مدة الإشتراك ",
    addCoupon: "ادخل كوبون",
    period: "المدة",
    save: "يمكنك توفير",
    save_per_year: "سنويا",
    country: "الدولة",
    ksa: "السعودية",
    pro: "برو",
    download_excel: "تحميل",
    upgrade_to_download: "ترقية الباقة لتتمكن من تحميل ملف الاكسيل",
    general: "أخبار عامة",
    positiveNews: "أخبار إيجابية",
    negativeNews: "أخبار سلبية",
    changeImg: "تغيير الصورة",
    searchList: "قائمة البحث",
    payment: "الدفع",
    not_include_tax: "السعر غير شامل الضريبة",
    noData: "لا توجد بيانات للعرض",
    newsletter: "النشرة البريدية",
    registerSuccess: "تم تسجيل الحساب بنجاح",
    welcomeMsg:
      "مرحبا بكم فى محلل.. سيتم توجيهكم الى صفحة ملخص السوق فى خلال ثوانى",
    marketerInfo: "بيانات المسوق",
    moreInfo: "بيانات اكثر",
    marketingMethod: "وسيلة التسويق",
    marketingType: "نوع العمولة",
    discountDate: "تاريخ نهاية الخصم",
    excludeDiscounts: "هل يتم استثناء الباقات المخفضة ؟",
    numberOfUsage: "عدد مرات الاستخدام الكلي",
    numberOfUsagePerClient: "عدد مرات الاستخدام للعميل الواحد",
    couponNoData: "لا يوجد",
    sales: "المبيعات",
    subscriptions: "الاشتراكات",
    clients: "العملاء",
    commissions: "العمولات",
    subscriptionNumber: "رقم الاشتراك",
    subscriptionCost: "قيمة الإشتراك",
    subscriptionDate: "تاريخ الإشتراك",
    moreThan: "اكثر من",
    lessThan: "اقل من",
    special_cost: "سعر الباقة بعد الخصم",
    discount_coupon: "خصم الكوبون",
    total_net_after_discount: "صافي المبلغ بعد الخصم",
  },
  headers: {
    exploreMohlel: "استكشف محلل",
    subscribe: "اشترك الآن",
    subscriptionPlan: "باقات الاشتراك",
    companyNews: "أخبار الشركات",
    market: "السوق",
    shareIndex: "مؤشر السوق الرئيسية (تاسي)",
    lessProfits: "المكررات الربحية الأدنى (PE)",
    highestProfits: "أعلى الشركات ربحية للسهم",
    highestMarket: "أعلى الشركات قيمة سوقية",
    features: "مميزاتنا",
    suggested: "مقترح لك",
    about: "عن الشركة",
    contact: "ابق على تواصل",
    suggestionOrProblem: "اقتراحك او مشكلتك",
    follow: "تابعنا",
    mainLinks: "الصفحات الرئيسية",
    company: "الشركة",
    contactSeller: "اتصل بالبائع",
    requestOrders: "ارسال طلب",
    comparePlans: "قارن بين الخطط المختلفة",
    forgotPassword: "نسيت كلمة المرور",
  },
  inputs: {
    name: "الاسم",
    email: "البريد الالكتروني",
    email_or_phone: "البريد الالكتروني او الهاتف",
    message: "الرسالة",
    phone: "رقم الهاتف",
    password: "كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
    confirmNewPassword: " تأكيد كلمة المرور الجديدة",
    verificationCode: "رمز التحقق",
    notes: "اكتب ملاحظاتك",
    truckModel: "نوع الشاحنة",
    truckBrand: "موديل الشاحنة",
    sellerCountry: "بلد البائع",
    phoneInvalid: "رقم هاتف غير صالح",
    nameOncard: "الاسم على الكارت",
    cardNumber: "رقم الكارت",
    expire: "تاريخ الانتهاء",
    cvv: "الرقم السري",
    title: "موضوع الرسالة",
    notes: "ملاحظات",
    time: "الوقت",
    date: "التاريخ",
    complaint: "شكوى",
    inquiry: "استفسار",
    partnership: "شراكة",
    others: "أخرى",
    coupon: "الكوبون",
    apply: "تطبيق",
    cancel: "الغاء",
    countryCode: "كود الدولة",
    withAramco: "مع أرامكو",
  },
  blocksMessages: {
    verification: "ادخل كود التأكيد الذي تم ارساله لهاتفك",
    noAccount: `ليس لديك عضوية ؟ `,
    creatAccount: "انشاء احساب",
    forgotPassword: "نسيت كلمة المرور ؟ ",
    haveAccount: "لديك عضوية بالفعل؟",
  },
  copyRights: {
    reserved: "جميع الحقوق محفوظة @",
    websiteName: "محلل 2023",
  },
  auth: {
    hello: "أهلًا بك،",
    startAccount: "ابدأ حسابك",
    free: "مجانًا ",
    now: "الآن",
    orChoose: "أو اختر أي من",
    plans: "باقات",
    mohalelPlans: "التحليل الاحترافي من محلل",
    onlyForAccounts: "هذه الخاصية متاحة للمسجلين فقط ",
    onlyForPlans: "هذه الخاصية ليست متاحة في باقتك",
    upgrade: "لترقية",
    plan: "الباقة",
    monthlyPlan: "الباقة الشهرية",
    annualPlan: "الباقة السنوية",
    loginByGoogle: "تسجيل الدخول باستخدام جوجل",
    helloAgain: "أهلًا بك مرة أخرى",
    loginTo: " سجل دخولك",
    manageYourAccount: "لإدارة حسابك",
    didYouReceiveMessage: "هل استلمت رمز التحقق؟",
    checkInboxAndJunk:
      "يرجى التأكد من فحص مجلدات البريد الوارد والمجلدات غير المرغوب فيها/البريد العشوائي",
    SendVerAgain: "ارسال مرة اخرى",
  },
  sectors: {
    companyInfo: "اسم الشركة",
    closingPrice: "سعر الاغلاق",
    priceMarket: "القيمة السوقية",
    capitalMoney: "رأس المال لمدفوع",
    multiplierBookValue: "مضاعف القيمة الدفترية",
    earningPerShare: "ربحية السهم",
    profitabilityBis: "مكرر الربحية",
    nextDistribution: "تاريخ التوزيع القادم",
    lastDistribution: "تاريخ اخر توزيع",
    annualDistributionRatio: "نسبة التوزيع السنوي",
    shares_outstanding: "الأسهم المصدرة ( بالمليون )",
  },
  DistributionDates: {
    announcementDate: " تاريخ الاعلان",
    dueDate: "تاريخ الاستحقاق",
    distributionDate: "تاريخ التوزيع",
    profitDistributionPolicy: "فترة التوزيع",
    annual_distribution_ratio: "عائد التوزيع%",
    total_cash_dividends: "إجمالي التوزيعات ( مليون ريال)",
    cash_distribution: "التوزيع النقدي ( ريال / سهم)",
    distribute_free_shares: "توزيع أسهم مجانية %",
    due_date: "تاريخ الاستحقاق",
    distribution_year: "السنة",
    current_price: "سعر السهم",
    profit_distribution_policy: "فترة التوزيع",
    all: "الكل",
    yearly: "سنوي",
    first_half: "النصف الأول",
    second_half: "النصف الثاني",
    first_quarter: "الربع الأول",
    second_quarter: "الربع الثاني",
    third_quarter: "الربع الثالث",
    fourth_quarter: "الربع الرابع",
    newest_to_oldest: "من الاحدث للأقدم",
    oldest_to_newest: "من الأقدم للأحدث",
    highest_to_lowest: "من الأعلى للأقل",
    lowest_to_highest: "من الأقل إلى الأعلى",
    unspecified: "غير محدد",
  },
  calendar: {
    mainCalendar: "التقويم",
    institutional_company_subscription: "اكتتاب الشركة للمؤسسات",
    individual_company_subscription: "اكتتاب الشركة للأفراد",
    profit_result: "نتائج الأرباح",
    cash_distributions: "التوزيعات النقدية",
    filter_upcomming_events: "تصفية الاحداث القادمة",
    choose_type: "اختر نوع الاحداث القادمة ليظهر لك ملف الاكسيل",
    show_article: "مشاهدة المقالة",
    news_details: "تفاصيل الخبر",
    company_code: "كود الشركة",
    company_name: "اسم الشركة",
    individual_subscription_date: "تاريخ بداية اكتتاب الافراد    ",
    institutional_subscription_date: "تاريخ بداية اكتتاب المؤسسات    ",
    market: "السوق ",
    price_range: "النطاق السعري    ",
    share_ratio: "نسبة الأسهم    ",
    stock_trade_date: "تاريخ تداول السهم",
    total_shares: "اجمالي الأسهم    ",
    announcement_date: "تاريخ الإعلان ",
    annual_distribution_ratio: "%نسبة التوزيع السنوي",
    cash_distribution: "أرباح التوزيعات",
    current_price: "السعر الحالي",
    distribute_free_shares: "توزيع أسهم مجانية %",
    distribution_date: "تاريخ التوزيع",
    dividend_price_profit: "التوزيع النقدي ( ريال/سهم)",
    due_date: "تاريخ الاستحقاق",
    total_cash_dividends: "إجمالي التوزيعات النقدية (ريال سعودي)",
  },
  company: {
    profile: "ملف الشركة",
    financial: "القوائم المالية",
    indicator_profit: "مؤشرات الربحية",
    indicator_cache: "مؤشرات السيولة",
    indicator_effeciancy: "مؤشرات الكفاءة",
    indicator_investments: "مؤشرات الاستثمارات",
    indicator_debit: "مؤشرات المديونية",
    compare: "المقارنة",
    dividants_history: " تاريخ التوزيعات النقدية",
    summary: "نبذة عن نشاط الشركة ",
    history: "تاريخ الشركة",
    news: "أخبار الشركة",
    Statement_of_Income: " المركز المالي",
    Balance_Sheet: "قائمة الدخل",
    Cash_Flow: " التدفق النقدي",
    price_today: "سعر السهم اليوم",
    price_share: "سعر السهم ",
    tasi: "تاسي",
    index: "المؤشر",
    main_index: "المؤشر الرئيسي",
    shares_outstanding: "الأسهم المصدرة",
    return_on_equity: "العائد على حقوق المساهمين",
    return_on_assets: "العائد على الأصول",
    price_to_sales: "مكرر المبيعات",
    book_value: "القيمة الدفترية",
    price_to_cash_flow: "مكرر التدفقات النقدية",
    title: "العنوان",
    addToCompare: "قارن",
    rmFromCompare: "حذف من المقارنة",
    addToFav: "متابعة",
    rmFromFav: "الغاء المتابعة",
    share: "مشاركة",
    copied: "تم النسخ",
  },
  rules: {
    upper: "يجب ان تشمل الأحرف الصغيرة والكبيرة، الأرقام ",
    eight: "يجب ان تحتوي على أكثر من 8 حروف أو أرقام",
    special: "يجب ان تشمل الحروف الخاصة",
  },
  months: {
    January: "يناير",
    February: "فبراير",
    March: "مارس",
    April: "أبريل",
    May: "مايو",
    June: "يونيو",
    July: "يوليو",
    August: "أغسطس",
    September: "سبتمبر",
    October: "أكتوبر",
    November: "نوفمبر",
    December: "ديسمبر",
  },
  invoice: {
    number: "رقم المعاملة",
    user_name: "الاسم",
    user_email: "البريد الالكتروني",
    user_phone: "الهاتف",
    date: "تاريخ المعاملة",
    plan: "الخطة",
    type: "نوع المعاملة",
    price: "السعر قبل الضريبة",
    tax: "الضريبة",
    price_tax: "السعر بعد الضريبة",
    total: "الاجمالي",
    mohlel_company: "شركة محلل  ",
    mohlel_numb: "سجل تجاري رقم :  ",
    mohlel_vat: "الرقم الضريبي :  ",
    print: "طباعة الفاتورة",
    price_tax_after_discount: "السعر بعد الخصم",
    price_tax_before_discount: "السعر قبل الخصم",
  },
  wallets: {
    highestProfit: "الأعلى ربحاً",
    highestLoss: "الأعلى خسارة",
    companyInfo: "اسم الشركة",
    lastPrice: "السعر الحالي",
    changePrice: "التغير",
    changePercent: "التغير%",
    walletBalance: "وزن المحفظة",
    sectors: "القطاعات",
    sector: "القطاع",
    shares: "الأسهم",
    sharesState: "حالة الأسهم",
    viewCustomization: "تخصيص العرض",
    defaultView: "العرض الإفتراضى",
    excelExport: "تصدير إكسل",
    purchasePrice: "متوسط سعر الشراء",
    sharesNum: "عدد الأسهم",
    cost: "التكلفة الكلية",
    holdingValue: "القيمة الحالية",
    holdingGain: "ربح/خسارة",
    holdingGainPercent: "%ربح/خسارة",
    ofPortfolio: "%نسبة الأسهم من المحفظة",
    PE_ratio: "مكرر الربحية",
    marketValue: "القيمة السوقية",
    earning_per_share: "ربحية السهم",
    annual_distribution_ratio: "%نسبة التوزيع السنوي",
    next_distribution: "تاريخ التوزيع القادم",
    cash_distribution: "التوزيعات بالريال",
    dividend_total: "إجمالى التوزيعات",
    total_gain_value: "إجمالى الربح",
    total_gain_percent: "% إجمالى الربح",
    options: "خيارات",
    capitalMoney: "رأس المال لمدفوع",
    shares_outstanding: "الأسهم المصدرة ( بالمليون )",
    multiplierBookValue: "مضاعف القيمة الدفترية",
    last_distribution: "تاريخ اخر توزيع",
    add_company_to_wallet: "أضف شركة إلى محفظتك الآن",
    walletsList: "قائمة المحافظ",
    sharesNum: "عدد الاسهم",
    priceAverage: "متوسط السعر",
    walletName: "اسم المحفظة",
    add_to_wallet: "إضافة إلى المحفظة",
    companies: "الشركات",
    purchasingPower: "القوة الشرائية",
    walletCost: "قيمة المحفظة",
    favWallet: "محفظة المفضلة",
    eventsCalendar: "تقويم الأحداث",
    createWallet: "إنشاء محفظة",
    compare: "مقارنة",
    compare_up_to: "قارن حتى 5 شركات",
    delete: "حذف",
    edit: "تعديل",
    save: "حفظ",
    create: "إنشاء",
    cancel: "إلغاء",
    companyName: "اسم الشركة",
    company_name_code: "اسم الشركة أو الرمز",
    columnsArrangement: "ترتيب الأعمدة",
    applySettings: "تطبيق الإعدادات",
    restoreDefaults: "إستعادة الوضع الإفتراضى",
    compare_limit_hint: "لا يجب ان يتجاوز عدد الشركات 5",
    net_icome_before_zakat: "نسبة صافي الدخل قبل الزكاة والضرائب EBT",
    assets_returns: "معدل العائد على الأصول ROA",
    equity_returns: "معدل العائد على حقوق الملكية ROE",
    cash_flow: "التدفقات النقدية من الأنشطة التشغيلية CFO",
    asset_turnover: "معدل دوران الأصول",
    indebtedness_ratio: "نسبة المديونية",
    book_value_share: "القيمة الدفترية للسهم",
    book_value_multiple: "مُضاعف القيمة الدفترية",
    earning_per_share: "مؤشر ربحية السهم EPS",
    return_per_share: "معدل العائد على السهم",
    cash_dividend_ratio: "نسبة التوزيع النقدي",
    PE_ratio: "مُكرر (مُضاعف) الربحية P/E",
    retained_earnings_ratio: "نسبة الأرباح المبقاة إلى حقوق الملكية",
    capital_change_percentage: "نسبة التغير في رأس مال الشركة",
    noShares: "لا توجدأسهم للعرض",
    addShares: "قم أولا بإضافة أسهم",
    confirmDelete: "هل تريد بالتأكيد الحذف؟",
  },
  payment: {
    thanks: "شكرًا على الإشتراك",
    goToInvoice: "اذهب إلى صفحة الفاتورة",
    goToHome: "اذهب إلى الصفحة الرئيسية",
    processFailed: "فشلت العملية! برجاء المحاولة مجدداً",
    returnSubscribe: "اذهب إلى صفحة الإشتراك",
    noUpgrade: "لا يمكنك ترقية هذه الباقة",
  },
};
