const moduleCalendar = {
  state: () => ({
    yearEvents: null,
    calendarTableData: null,
  }),
  actions: {
    async getYearEvents(context, payload) {
      try {
        await this.$axios
          .get(`/calendar`, {
            params: {
              year: payload.year,
              code: payload.code,
            },
          })
          .then((response) => {
            if (response.data) {
              context.commit("setYearEvents", response.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getCalendarTable(context, payload) {
      try {
        await this.$axios
          .get(`/calendar/table`, {
            params: {
              year: payload.year,
              code: payload.code,
              next: payload.next,
              per_page: payload.perPage,
              page: payload.page,
            },
          })
          .then((response) => {
            if (response.data) {
              context.commit("setCalendarTable", response.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setYearEvents(state, payload) {
      state.yearEvents = payload;
    },
    setCalendarTable(state, payload) {
      state.calendarTableData = payload;
    },
  },
  getters: {},
};

export default moduleCalendar;
