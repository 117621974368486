import Vue from "vue";
import VueGtag from "vue-gtag";

const vueGtagClient = ({ app, env }) => {
  Vue.use(
    VueGtag,
    {
      config: { id: env.measurementId },
    },
    app.router
  );
};

export default vueGtagClient;
