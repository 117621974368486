export default function ({ $gtm, app, env }) {
    $gtm.init(env.gtmId);
    window.onNuxtReady(() => {
        app.router.afterEach((to, from) => {
            $gtm.push({
                event: to.fullPath,
                pagePath: to.fullPath,
            });
        });
    })
}
