const moduleIndex = {
  state: () => ({}),
  actions: {
    async nuxtServerInit({ dispatch, commit }) {
      // commit("general/setServer");
      // var requestOptions = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-Requested-With": "XMLHttpRequest",
      //     "Accept-Language": "ar",
      //   },
      //   redirect: "follow",
      // };
      // //  console.log(ctx.getters['i18n/localeRouteParams'])
      // await dispatch('home/getSiteSettings' , requestOptions)
    },
  },
  mutations: {},
  getters: {},
};

export default moduleIndex;
