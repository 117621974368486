const moduleGeneral = {
  state: () => ({
    page: null,

  }),
  actions: {
    async getSinglePage(context, payload) {
      try {
        await this.$axios
          .get(`/page` , {
            params:{
              slug: payload.slug
            }
          })
          .then((response) => {
            context.commit("setSinglePage", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setSinglePage(state, payload) {
      state.page = payload;
    },
  },
  getters: {},
};

export default moduleGeneral;
