const moduleTables = {
  state: () => ({
    sectors: null,
    shares: null,
    profits: null,
    upcomingIPOS: null,
    profitDistributionsDates: null,
    profitDistributions: null,
    explorer: null,
  }),
  actions: {
    async getSectors(context, payload) {
      let filter = "";
      if (payload.filterby != null) {
        payload.filterby.forEach((element) => {
          filter += `&${element.key}=${element.value}`;
        });
      }
      try {
        await this.$axios
          .get(
            `/sectors/companies?per_page=${payload.perPage}&page=${
              payload.page
            }${payload.search ? "&search=" + payload.search : ""}${
              filter !== "" ? filter : ""
            } `
          )
          .then((response) => {
            context.commit("totalSectors", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getProfits(context, payload) {
      try {
        await this.$axios
          .get(
            `/market-summary/profitability-bis?records_num=${payload.records_num}`
          )
          .then((response) => {
            context.commit("profits", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getShares(context, payload) {
      try {
        await this.$axios
          .get(
            "https://api.instantwebtools.net/v1/passenger?size=" +
              payload.perPage +
              "&page=" +
              payload.page
          )
          .then((response) => {
            context.commit("shares", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getupcomingIPOS(context, payload) {
      try {
        await this.$axios
          .get(
            "https://api.instantwebtools.net/v1/passenger?size=" +
              payload.perPage +
              "&page=" +
              payload.page
          )
          .then((response) => {
            context.commit("upcomingIPOS", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },

    async getProfitDistributionsDates(context, payload) {
      try {
        await this.$axios
          .get(`/company/profit-distribution?slug=${payload.slug}`)
          .then((response) => {
            context.commit("setProfitDistributionsDates", response.data);
          })
          .catch((_error) => {
            console.log("error", _error);
          });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getProfitDistributions(context, payload) {
      try {
        await this.$axios
          .get(`/profit-distribution`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search,
              ...payload.filterby,
            },
          })
          .then((response) => {
            context.commit("setProfitDistributions", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getExplorer(context, payload) {
      let filter = "";
      if (payload.filterby != null) {
        let filters = Object.keys(payload.filterby);
        filters.forEach((key, index) => {
          filter += `&${key}=${payload.filterby[key]}`;
        });
      }
      try {
        await this.$axios
          .get(`/mohlel-explorer?${filter !== "" ? filter : ""}`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search ? payload.search : null,
            },
          })
          .then((response) => {
            //console.log(response)
            context.commit("setExplorer", response.data.data);
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    totalSectors(state, data) {
      return (state.sectors = data);
    },
    profits(state, data) {
      return (state.profits = data);
    },
    shares(state, data) {
      return (state.shares = data);
    },
    upcomingIPOS(state, data) {
      return (state.upcomingIPOS = data);
    },
    setProfitDistributionsDates(state, data) {
      return (state.profitDistributionsDates = data);
    },
    setProfitDistributions(state, data) {
      return (state.profitDistributions = data);
    },
    setExplorer(state, data) {
      return (state.explorer = data);
    },
  },
  getters: {},
};

export default moduleTables;
