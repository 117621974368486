const favorites = {
  state: () => ({
    favoritesList: null,
  }),
  actions: {
    async getFavoritesList(context, payload) {
      try {
        await this.$axios
          .get(`/favourite?page=${payload.page}&per_page=${payload.per_page}`)
          .then((response) => {
            context.commit("setFavoritesList", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setFavoritesList(state, payload) {
      state.favoritesList = payload;
    },
  },
  getters: {},
};

export default favorites;
