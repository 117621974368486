
import { mapState } from "vuex";
export default {
  name: "DefaultLayout",
  mounted() {},
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "mdi-apps",
          title: "Welcome",
          to: "/",
        },
        {
          icon: "mdi-chart-bubble",
          title: "Inspire",
          to: "/inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  computed: {
    ...mapState("home", [
      "siteSettings",
      {
        Item: (state) => state,
      },
      "homeSections",
    ]),

    extraClass() {
      let className = null;
      if (
        this.$route.name == "index___ar" ||
        this.$route.name == "index___en"
      ) {
        className = null;
      } else {
        className = "innperPages";
      }
      return className;
    },
  },
  beforeCreate() {
    if (this.$i18n.localeProperties.rtl == "true") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
  async fetch() {
    console.log("DefaultLayout");

    //  this.$nextTick(() => {
    //    this.$nuxt.$loading.start()
    //    console.log(this.$nuxt.$loading.loading);
    //  })
    //  setTimeout(() => this.$nuxt.$loading.finish(), 1500)

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": this.$i18n.localeProperties.code,
      },
      redirect: "follow",
    };
    await this.$store
      .dispatch("home/getSiteSettings", requestOptions)
      .then((res) => {
        // setTimeout(() => this.$nuxt.$loading.finish(), 500)
      });
  },
  head() {
    const canonicalUrl = "https://www.mohlel.com" + this.$route.path;
    return {
      title: "الرياض ، المملكة العربية السعودية",
      link: [
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ],
      meta: [
        {
          property: "og:title",
          content: "الرياض ، المملكة العربية السعودية",
        },
        {
          property: "og:description",
          content:
            "منصة تقدم بيانات ومؤشرات مالية تساعد المستثمرين فى تحليل الشركات المدرجة فى اسواق المال  .. مرخصة من تداول السعودية برقم 26483473 لسنة 2020",
        },
        {
          property: "og:image",
          content: "/logo.svg", // Replace with the URL of an image associated with your content
        },
        {
          property: "og:url",
          content: canonicalUrl,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          hid: "description",
          name: "description",
          content:
            "منصة تقدم بيانات ومؤشرات مالية تساعد المستثمرين فى تحليل الشركات المدرجة فى اسواق المال  .. مرخصة من تداول السعودية برقم 26483473 لسنة 2020",
        },
        {
          name: "description",
          content:
            "منصة تقدم بيانات ومؤشرات مالية تساعد المستثمرين فى تحليل الشركات المدرجة فى اسواق المال  .. مرخصة من تداول السعودية برقم 26483473 لسنة 2020",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            "منصة تقدم بيانات ومؤشرات مالية تساعد المستثمرين فى تحليل الشركات المدرجة فى اسواق المال  .. مرخصة من تداول السعودية برقم 26483473 لسنة 2020",
          property: "og:description",
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content:
            "منصة تقدم بيانات ومؤشرات مالية تساعد المستثمرين فى تحليل الشركات المدرجة فى اسواق المال  .. مرخصة من تداول السعودية برقم 26483473 لسنة 2020",
        },

        {
          hid: "og:title",
          name: "og:title",
          content: "الرياض ، المملكة العربية السعودية",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: "الرياض ، المملكة العربية السعودية",
        },
        {
          hid: "json-ld-schema",
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            name: "Your Website Name",
            url: "https://www.mohlel.com",
            potentialAction: {
              "@type": "SearchAction",
              target: "https://www.mohlel.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          }),
        },
      ],
    };
  },
};
