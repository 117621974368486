const moduleGeneral = {
  state: () => ({
    posts: null,
    currentPost: null,
    allSectors: null,
    server: null,
    allProfitDistributionPolicy: [],
    SSR: null,
    userProfile: null,
    isLightTheme: true,
  }),
  actions: {
    // Action to toggle the theme
    toggleTheme({ commit, state }) {
      commit("setTheme", !state.isLightTheme);
      // Store the user's theme preference in local storage
      localStorage.setItem("theme", state.isLightTheme ? "light" : "dark");
    },
    async getPosts(context, payload) {
      try {
        await this.$axios
          .get(`/home/company-blogs`, {
            params: {
              per_page: payload.per_page,
              page: payload.page,
              slug: payload.slug,
              type: payload.type,
              ...payload.filterBy,
            },
          })
          .then((response) => {
            context.commit("totalPosts", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getPostsByID(context, payload) {
      try {
        await this.$axios
          .get(`/home/company-blogs/${payload.slug}/show`)
          .then((response) => {
            context.commit("setCurrentPost", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllSectors(context, payload) {
      try {
        await this.$axios
          .get(`/sectors`)
          .then((response) => {
            context.commit("setAllSectors", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getProfitDistributionPolicy(context, payload) {
      try {
        await this.$axios
          .get(`/profit-distribution/policy`)
          .then((response) => {
            context.commit("setProfitDistributionPolicy", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSSR(context, payload) {
      try {
        await this.$axios
          .get(`/home/rss`)
          .then((response) => {
            context.commit("setSSR", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getUserProfile(context) {
      try {
        await this.$axios
          .get(`/auth/profile`)
          .then((response) => {
            context.commit("setUserProfile", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    // Mutation to set the theme
    setTheme(state, isLightTheme) {
      state.isLightTheme = isLightTheme;
    },
    totalPosts(state, payload) {
      state.posts = payload;
    },
    setCurrentPost(state, payload) {
      state.currentPost = payload;
    },
    setServer(state) {
      state.server = this.$config.baseServerUrl;
    },
    setAllSectors(state, payload) {
      state.allSectors = payload;
    },
    setProfitDistributionPolicy(state, payload) {
      state.allProfitDistributionPolicy = payload;
    },
    setSSR(state, payload) {
      state.SSR = payload;
    },
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },
  },
  getters: {
    // Getter to get the current theme
    currentTheme(state) {
      return state.isLightTheme ? "light" : "dark";
    },
  },
};

export default moduleGeneral;
