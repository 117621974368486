import { getField, updateField } from "vuex-map-fields";
import Cookies from "js-cookie";

const moduleUser = {
  state: () => ({
    user: null,
    validated: false,
    userPlans: [],
    userPayments: null,
    sortableHeaderEn: null,
    sortableHeaderAr: null,
    storedCoupon: null,
  }),
  actions: {
    async getUserPlans(context, payload) {
      try {
        await this.$axios
          .get(`/tap/plans`, {
            params: {
              code: payload.code,
            },
          })
          .then((response) => {
            context.commit("setUserPlans", response.data.data);
            return response.data.message
          })
          .catch((_error) => {
            throw  _error.response.data.message;
          });
      } catch (e) {
        throw e;
      }
    },
    async getUserPayments(context, payload) {
      try {
        await this.$axios
          .get(`/transactions`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
            },
          })
          .then((response) => {
            context.commit("setUserPayments", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSortableHeaderEn(context, payload) {
      const header = localStorage.getItem("sortableHeaderEn")
        ? JSON.parse(localStorage.getItem("sortableHeaderEn"))
        : null;
      context.commit("setSortableHeaderEn", {
        data: header,
        cookies: false,
      });
    },
    async getSortableHeaderAr(context, payload) {
      const header = localStorage.getItem("sortableHeaderAr")
        ? JSON.parse(localStorage.getItem("sortableHeaderAr"))
        : null;
      context.commit("setSortableHeaderAr", {
        data: header,
        cookies: false,
      });
    },
    async getstoredCoupon(context, payload) {
      // get from cookies
      console.log(Cookies.get("storedCoupon"))
      const storedCoupon = Cookies.get("storedCoupon")
        ? Cookies.get("storedCoupon")
        : null;
      context.commit("setStoredCoupon", {
        data: storedCoupon,
        cookies: false,
      });
    },
  },
  mutations: {
    setUserPlans(state, payload) {
      state.userPlans = payload;
    },
    setUserPayments(state, payload) {
      state.userPayments = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },
    setAuthenticated(state, validated) {
      state.validated = validated;
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    },
    // payload {data: , cookies: true/false}
    setSortableHeaderEn(state, payload) {
      state.sortableHeaderEn = payload.data;
      if (payload.cookies == true) {
        let payloadData = JSON.stringify(payload.data);
        localStorage.setItem("sortableHeaderEn", payloadData);
      }
    },
    setSortableHeaderAr(state, payload) {
      state.sortableHeaderAr = payload.data;
      if (payload.cookies == true) {
        let payloadData = JSON.stringify(payload.data);
        localStorage.setItem("sortableHeaderAr", payloadData);
      }
    },
    setStoredCoupon(state, payload) {
      state.storedCoupon = payload.data;
      if (payload.cookies == true) {
        let payloadData = payload.data;
        Cookies.set("storedCoupon", payloadData);
      }
    },
    updateField,
  },
  getters: {
    getField,
  },
};

export default moduleUser;
