import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=93f620a6&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAdminIncludesHeader: require('/root/mohlel-front/components/user-admin/includes/Header.vue').default,UserAdminIncludesSidebar: require('/root/mohlel-front/components/user-admin/includes/Sidebar.vue').default,AppIncludesFooter: require('/root/mohlel-front/components/app/includes/Footer.vue').default})
