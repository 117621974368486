const moduleGeneral = {
  state: () => ({
    companyProfile: null,
    companyGeneralData: null,
    financialLists: null,
    profitabilityIndicators: null,
    liquidityIndicators: null,
    EfficiencyIndicators: null,
    valueIndicators: null,
    indebtednessIndicators: null,
    companyComparisons: null,
    companyStockdio:null
  }),
  actions: {
    async getCompanyProfile(context, payload) {
      try {
        await this.$axios
          .get(`/company/profile?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setCompanyProfile", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getCompanyStockdio(context, payload) {
      try {
        // ${payload.slug}
        await this.$axios
          .get(`company/financial/prices`,{params:{
            ...payload
          }})
          .then((response) => {
            if (response.data.data) {
              context.commit("setCompanyStockdio", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getFinancialLists(context, payload) {
      try {
        await this.$axios
          .get(
            `/company/financial-lists?slug=${payload.slug}&file_type=${payload.file_type}&yearly_type=${payload.yearly_type}`
          )
          .then((response) => {
            if (response.data.data) {
              context.commit("setFinancialLists", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getProfitabilityIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/company/profitability-indicators?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setProfitabilityIndicators", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getliquidityIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/company/liquidity-indicators?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setLiquidityIndicators", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getEfficiencyIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/company/efficiency-indicators?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setEfficiencyIndicators", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getValueIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/company/value-indicators?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setValueIndicators", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getIndebtednessIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/company/indebtedness-indicators?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setIndebtednessIndicators", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getCompanyComparisons(context, payload) {
      try {
        await this.$axios
          .get(`/company/comparison?slug=${payload.slug}`)
          .then((response) => {
            if (response.data.data) {
              context.commit("setCompanyComparisons", response.data.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setCompanyProfile(state, payload) {
      state.companyProfile = payload;
    },
    setCompanyStockdio(state, payload) {
      state.companyStockdio = payload;
    },
    setCompanyGeneralData(state, payload) {
      state.companyGeneralData = payload;
    },
    setFinancialLists(state, payload) {
      state.financialLists = payload;
    },
    setProfitabilityIndicators(state, payload) {
      state.profitabilityIndicators = payload;
    },
    setLiquidityIndicators(state, payload) {
      state.liquidityIndicators = payload;
    },
    setEfficiencyIndicators(state, payload) {
      state.EfficiencyIndicators = payload;
    },
    setValueIndicators(state, payload) {
      state.valueIndicators = payload;
    },
    setIndebtednessIndicators(state, payload) {
      state.indebtednessIndicators = payload;
    },
    setCompanyComparisons(state, payload) {
      state.companyComparisons = Object.keys(payload).map((k) => payload[k]);
    },
  },
  getters: {},
};

export default moduleGeneral;
