// await this.$axios.get('api/home/site-settings' , payload).then((response) => {
//   context.commit("setSiteSettings", response.data);
// })

const moduleHome = {
  state: () => ({
    aboutUs: null,
    whyUs: null,
    plans: null,
    companiesStats: null,
    stockdioCompanies: null,
    reviews: null,
    siteSettings: { email: "" },
    plansError: null,
    homeSections: null,
    features: [],
    isRequestLoading: false,
  }),
  actions: {
    async getSections(context, payload) {
      // console.log(context.rootState.general.server);
      try {
        return await new Promise((resolve, reject) => {
          return this.$axios
            .get(`/home/sections`)
            .then((response) => {
              resolve(response.data.data);
              context.commit("setSections", response.data.data);
            })
            .catch((error) => console.log("error", error));
        });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getPlans(context, payload) {
      // console.log(context.rootState.general.server);
      try {
        await this.$axios
          .get(`/home/plans`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            context.commit("setPlans", response.data.data);
            console.log(response.data.errors);
            if (response.data.errors != "[]") {
              context.commit("setPlansError", response.data.errors.message);
            }
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCompaniesStats(context, payload) {
      // console.log(context.rootState.general.server);
      try {
        await this.$axios
          .get(`/home/companies/historical-prices`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            context.commit("setCompaniesStats", response.data.data);
            console.log(response.data.errors);
            if (response.data.errors != "[]") {
              context.commit(
                "setCompaniesStatsError",
                response.data.errors.message
              );
            }
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getStockdioCompanies(context, payload) {
      // console.log(context.rootState.general.server);
      console.log(payload);
      try {
        await this.$axios
          .get(`/home/companies-for-stockdio`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            context.commit("setStockdioCompanies", response.data.data);
            console.log(response.data.data);
            if (response.data.errors != "[]") {
              context.commit(
                "setStockdioCompaniesError",
                response.data.errors.message
              );
            }
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getReviews(context, payload) {
      try {
        await this.$axios
          .get(`/home/reviews`)
          .then((response) => {
            context.commit("setReviews", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getFeatures(context, payload) {
      try {
        await this.$axios
          .get(`/home/features`)
          .then((response) => {
            context.commit("setFeatures", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSiteSettings(context, payload) {
      const server = this.$axios.defaults.baseURL;
      console.log(server, "server");

      try {
        return await new Promise((resolve) => {
          fetch(`${server}/home/site-settings`, payload)
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              resolve(result.data);
              context.commit("setSiteSettings", result.data);
            })
            .catch((error) => console.log("error", error));
        });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAboutUs(context, payload) {
      try {
        await this.$axios
          .get(`/home/about-us`)
          .then((response) => {
            context.commit("setAboutUs", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getWhyUs(context, payload) {
      try {
        await this.$axios
          .get(`/home/why-mohlel`)
          .then((response) => {
            context.commit("setWhyUs", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    saveLoadingStatus(state, payload) {
      state.isRequestLoading = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    setCompaniesStats(state, payload) {
      state.companiesStats = payload;
    },
    setStockdioCompanies(state, payload) {
      state.stockdioCompanies = payload;
    },
    setReviews(state, payload) {
      state.reviews = payload;
    },
    setSiteSettings(state, payload) {
      state.siteSettings = payload;
    },
    setAboutUs(state, payload) {
      state.aboutUs = payload;
    },
    setWhyUs(state, payload) {
      state.whyUs = payload;
    },
    setPlansError(state, payload) {
      state.plansError = payload;
    },
    setCompaniesStatsError(state, payload) {
      state.companiesStatsError = payload;
    },
    setStockdioCompaniesError(state, payload) {
      state.stockdioCompaniesError = payload;
    },
    setSections(state, payload) {
      state.homeSections = payload;
    },
    setFeatures(state, payload) {
      state.features = payload;
    },
  },
  getters: {},
};

export default moduleHome;
