const wallets = {
  state: () => ({
    allWallets: null,
    companiesWithShares: null,
  }),
  actions: {
    async getAllWallets(context, payload) {
      try {
        await this.$axios
          .get(`/wallets?page=${payload.page}&per_page=${payload.per_page}`)
          .then((response) => {
            context.commit("setAllWallets", response.data.data);
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        throw error;
      }
    },
    async getCompaniesWithShares(context , payload) {
      try {
        await this.$axios
          .get(`/wallets/companies-with-shares` , {
            params: {
             ...payload
            },
          })
          .then((response) => {
            context.commit("setCompaniesWithShares", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setAllWallets(state, payload) {
      state.allWallets = payload;
    },
    setCompaniesWithShares(state, payload) {
      state.companiesWithShares = payload;
    },
  },
  getters: {},
};

export default wallets;
