const myShares = {
  state: () => ({
    walletStatistics: null,
    walletBalance: null,
    walletsProfit: null,
    companiesShares: null,
    walletsNews: null,
    sharesCalendar: null,
    defaultSharesHeader: {
      en: [
        {
          id: 1,
          text: "Company name",
          value: "logo",
          align: "start",
          show: true,
          default: true,
          cellClass: "company-name-td",
        },
        {
          id: 2,
          text: "Sector",
          value: "sector",
          align: "center",
          show: true,
          default: true,
          cellClass: "company-section-td",
        },
        {
          id: 3,
          text: "Last price",
          value: "last_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 4,
          text: "changePrice",
          value: "change_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 5,
          text: "Purchase price",
          value: "purchase_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 6,
          text: "Number of shares",
          value: "number_shares_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 7,
          text: "Cost",
          value: "cost_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 8,
          text: "Holding value",
          value: "holding_value_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 9,
          text: "Holding gain %",
          value: "holding_gain_percent",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 10,
          text: "Holding gain",
          value: "holding_gain_value_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 11,
          text: "% Of portfolio",
          value: "percent_of_protofolio",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 12,
          text: "P/E ratio",
          value: "profitability_bis_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 13,
          text: "Market value",
          value: "market_value_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 14,
          text: "Earning per share",
          value: "earning_per_share_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 15,
          text: "Annual distribution ratio%",
          value: "annual_distribution_ratio",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 16,
          text: "Next Dividends",
          value: "next_distribution",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 17,
          text: "Dividends",
          value: "cash_distribution_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 18,
          text: "Dividend total",
          value: "dividend_total_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 19,
          text: "Total gain value",
          value: "total_gain_value_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 20,
          text: "Total gain percent",
          value: "total_gain_percent",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 21,
          text: "Options",
          value: "shares-options",
          align: "center",
          show: true,
          default: true,
        },
      ],
      ar: [
        {
          id: 1,
          text: "اسم الشركة",
          value: "logo",
          align: "start",
          show: true,
          default: true,
          cellClass: "company-name-td",
        },
        {
          id: 2,
          text: "القطاع",
          value: "sector",
          align: "center",
          show: true,
          default: true,
          cellClass: "company-section-td",
        },

        {
          id: 3,
          text: "السعر الحالي",
          value: "last_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 4,
          text: "التغير",
          value: "change_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 5,
          text: "متوسط سعر الشراء",
          value: "purchase_price_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 6,
          text: "عدد الأسهم",
          value: "number_shares_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 7,
          text: "التكلفة الكلية",
          value: "cost_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 8,
          text: "القيمة الحالية",
          value: "holding_value_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 9,
          text: "%ربح/خسارة",
          value: "holding_gain_percent",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 10,
          text: "ربح/خسارة",
          value: "holding_gain_value_shares",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 11,
          text: "%نسبة الأسهم من المحفظة",
          value: "percent_of_protofolio",
          align: "center",
          show: true,
          default: true,
        },
        {
          id: 12,
          text: "مكرر الربحية",
          value: "profitability_bis_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 13,
          text: "القيمة السوقية",
          value: "market_value_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 14,
          text: "ربحية السهم",
          value: "earning_per_share_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 15,
          text: "%نسبة التوزيع السنوي",
          value: "annual_distribution_ratio",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 16,
          text: "تاريخ التوزيع القادم",
          value: "next_distribution",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 17,
          text: "التوزيعات بالريال",
          value: "cash_distribution_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 18,
          text: "إجمالى التوزيعات",
          value: "dividend_total_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 19,
          text: "إجمالى الربح",
          value: "total_gain_value_shares",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 20,
          text: "% إجمالى الربح",
          value: "total_gain_percent",
          align: "center",
          show: false,
          default: false,
        },
        {
          id: 21,
          text: "خيارات",
          value: "shares-options",
          align: "center",
          show: true,
          default: true,
        },
      ],
    },
    logCompaniesShares: null,
  }),
  actions: {
    async getWalletStatistics(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/statistics/${payload.id}`)
          .then((response) => {
            context.commit("setWalletStatistics", response.data.data);
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getWalletBalance(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/balance/${payload.id}?type=${payload.type}`)
          .then((response) => {
            //console.log(response.data.data)
            context.commit("setWalletBalance", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getWalletsProfit(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/profit/${payload.id}?type=${payload.type}`)
          .then((response) => {
            context.commit("setWalletsProfit", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCompaniesShares(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/companies-shares/${payload.id}`, {
            params: {
              per_page: payload.per_page,
            },
          })
          .then((response) => {
            console.log("setCompaniesShares", response.data.data);
            context.commit("setCompaniesShares", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getWalletsNews(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/companies-blogs/${payload.id}`, {
            params: {
              per_page: payload.per_page,
              page: payload.page,
              slug: payload.slug,
              type: payload.type,
              ...payload.filterBy,
            },
          })
          .then((response) => {
            context.commit("setWalletsNews", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSharesCalendar(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/companies-calendar/${payload.id}`, {
            params: {
              year: payload.year,
              code: payload.code,
            },
          })
          .then((response) => {
            if (response.data) {
              context.commit("setSharesCalendar", response.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getLogCompaniesShares(context, payload) {
      try {
        await this.$axios
          .get(`/wallets/log-companies-shares/${payload.id}`)
          .then((response) => {
            if (response.data) {
              context.commit("setLogCompaniesShares", response.data);
            }
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setWalletStatistics(state, payload) {
      state.walletStatistics = payload;
    },
    setWalletBalance(state, payload) {
      state.walletBalance = payload;
    },
    setWalletsProfit(state, payload) {
      state.walletsProfit = payload;
    },
    setCompaniesShares(state, payload) {
      state.companiesShares = payload;
    },
    setWalletsNews(state, payload) {
      state.walletsNews = payload;
    },
    setSharesCalendar(state, payload) {
      state.sharesCalendar = payload;
    },
    setLogCompaniesShares(state, payload) {
      state.logCompaniesShares = payload;
    },
  },
  getters: {},
};

export default myShares;
