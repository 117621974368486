// await this.$axios.get('api/home/site-settings' , payload).then((response) => {
//   context.commit("setSiteSettings", response.data);
// })

const moduleMarketSum = {
  state: () => ({
    statics: null,
    EarningPerShare: null,
    MarketValue: null,
    allSectors: null,
    sectors: null,
    companiesBySearch: null,
  }),
  actions: {
    async getStatics(context, payload) {
      // console.log(payload);
      // console.log(context.rootState.general.server);
      try {
        // console.log(context)
        await this.$axios
          .get(`/market-summary/statistics`)
          .then((response) => {
            context.commit("setStatics", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getEarningPerShare(context, payload) {
      // console.log(context.rootState.general.server);
      try {
        // console.log(payload)
        await this.$axios
          .get(
            `/market-summary/earning-per-share?records_num=${payload.records_num}`
          )
          .then((response) => {
            context.commit("setEarningPerShare", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getMarketValue(context, payload) {
      // console.log(context.rootState.general.server);
      try {
        // console.log(payload)
        await this.$axios
          .get(
            `/market-summary/market-value?records_num=${payload.records_num}`
          )
          .then((response) => {
            context.commit("setMarketValue", response.data.data);
          })
          .catch((error) => console.log("error", error));
      } catch (e) {
        console.log("error", e);
      }
    },
    async getAllSectors(context, payload) {
      try {
        await this.$axios
          .get(`/market-summary/sectors`)
          .then((response) => {
            context.commit("setAllSectors", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getSectors(context, payload) {
      let filter = "";
      if (payload.filterby != null) {
        payload.filterby.forEach((element) => {
          filter += `&${element.key}=${element.value}`;
        });
      }
      try {
        await this.$axios
          .get(
            `/market-summary/companies-by-sector?per_page=${
              payload.perPage
            }&page=${payload.page}${
              payload.search ? "&search=" + payload.search : ""
            }${filter !== "" ? filter : ""} `
          )
          .then((response) => {
            context.commit("totalSectors", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCompaniesBySearch(context, payload) {
      try {
        await this.$axios
          .get(`/market-summary/companies-by-sector`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              search: payload.search,
            },
          })
          .then((response) => {
            context.commit("setCompaniesBySearch", response.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
  },
  mutations: {
    setStatics(state, payload) {
      state.statics = payload;
    },
    setEarningPerShare(state, payload) {
      state.EarningPerShare = payload;
    },
    setMarketValue(state, payload) {
      state.MarketValue = payload;
    },
    setAllSectors(state, payload) {
      state.allSectors = payload;
    },
    totalSectors(state, data) {
      return (state.sectors = data);
    },
    setCompaniesBySearch(state, data) {
      return (state.companiesBySearch = data);
    },
  },
  getters: {},
};

export default moduleMarketSum;
