const wallets = {
  state: () => ({
    comparedShares: null,
    profitabilityIndicators: null,
    efficiencyIndicators: null,
    indebtednessIndicators: null,
    liquidityIndicators: null,
    valueIndicators: null,
    comparedCompanies: null,
  }),
  actions: {
    async getComparedShares(context, payload) {
      try {
        await this.$axios
          .get(`/comparison`, {
            params: {
              per_page: payload.perPage,
              page: payload.page,
              companies_ids: payload.companies_ids,
            },
          })
          .then((response) => {
            context.commit("setComparedShares", response.data.data);
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getProfitabilityIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/comparison/profitability-indicators`, {
            params: {
              companies_ids: payload.companies_ids,
              item: payload.item,
              type: payload.type,
            },
          })
          .then((response) => {
            context.commit("setProfitabilityIndicators", response.data.data);
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
        console.log("error", e);
      }
    },
    async getEfficiencyIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/comparison/efficiency-indicators`, {
            params: {
              companies_ids: payload.companies_ids,
              item: payload.item,
              type: payload.type,
            },
          })
          .then((response) => {
            context.commit("setEfficiencyIndicators", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getIndebtednessIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/comparison/indebtedness-indicators`, {
            params: {
              companies_ids: payload.companies_ids,
              item: payload.item,
              type: payload.type,
            },
          })
          .then((response) => {
            context.commit("setIndebtednessIndicators", response.data.data);
          })
          .catch((error) => {
            throw error;
          });
      } catch (e) {
        throw e;
      }
    },
    async getLiquidityIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/comparison/liquidity-indicators`, {
            params: {
              companies_ids: payload.companies_ids,
              item: payload.item,
              type: payload.type,
            },
          })
          .then((response) => {
            context.commit("setLiquidityIndicators", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getValueIndicators(context, payload) {
      try {
        await this.$axios
          .get(`/comparison/value-indicators`, {
            params: {
              companies_ids: payload.companies_ids,
              item: payload.item,
              type: payload.type,
            },
          })
          .then((response) => {
            context.commit("setValueIndicators", response.data.data);
          })
          .catch((_error) => {});
      } catch (e) {
        console.log("error", e);
      }
    },
    async getComparedCompanies(context) {
      try {
        await this.$axios
          .get(`/compares`, {
            params: {
              per_page: 5,
              page: 1,
            },
          })
          .then((response) => {
            context.commit("setComparedCompanies", response.data.data.data);
          })
          .catch((_error) => {
            throw _error;
          });
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    setComparedShares(state, payload) {
      state.comparedShares = payload;
    },
    setProfitabilityIndicators(state, payload) {
      state.profitabilityIndicators = payload;
    },
    setEfficiencyIndicators(state, payload) {
      state.efficiencyIndicators = payload;
    },
    setIndebtednessIndicators(state, payload) {
      state.indebtednessIndicators = payload;
    },
    setLiquidityIndicators(state, payload) {
      state.liquidityIndicators = payload;
    },
    setValueIndicators(state, payload) {
      state.valueIndicators = payload;
    },
    setComparedCompanies(state, payload) {
      state.comparedCompanies = payload;
    },
  },
  getters: {},
};

export default wallets;
