// plugins/vue-format-global.js
import Vue from "vue";
import moment from "moment";
import { isEmail } from "validator";
var mixin = {
  data() {
    return {
      frozen: false,
      authDate: null,
      planHasAccess: false,
      ErrorType: null,
      windowWidth: null,
      timer: null
    };
  },
  created() {
    // moment.locale("ar");
  },
  mounted() {

    this.$nextTick(() => {
      this.windowWidth = window.innerWidth
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth
      });

    })

  },
  methods: {
    counterDown(expireDate) {
      this.frozen = true
      let now = this.$moment(); //todays date
      let end = this.$moment(expireDate); // another date
      let duration = this.$moment.duration(end.diff(now));
      this.authDate = duration;
      //console.log(now,end,duration, expireDate, "==========");
      this.timer = setInterval(() => {
        this.authDate = this.authDate.subtract(1, "seconds");
        if (this.$moment.duration(this.authDate).asSeconds() <= 0) {
          clearInterval(this.timer);
          this.frozen = false
        }
      }, 1000);
    },
    handelError(error, type, withCountDown, verification = false) {
      if (error.response.data.status == 400) {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors);
        }
        if (
          type == "phone" &&
          error.response.data.errors &&
          error.response.data.errors.phone
        ) {
          this.$refs.observer.setErrors({
            email: error.response.data.errors.phone,
          });
        }
        if (error.response.data.message) {
          this.$toast.error(error.response.data.message);
        }
        if (withCountDown) {
          //console.log(error.response.data)
          this.counterDown(error.response.data.data.expire_at);
        }
      } else {
        this.$toast.error(error.response.data.message);
      }
    },
    handelStatusError(error, type) {
      // Inputs errors
      if (error.response.data.status == 400) {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors);
        }
        if (
          type == "phone" &&
          error.response.data.errors &&
          error.response.data.errors.phone
        ) {
          this.$refs.observer.setErrors({
            email: error.response.data.errors.phone,
          });
        }
        if (error.response.data.message) {
          this.$toast.error(error.response.data.message);
        }
        // (unauth) => 401;  // (banned) => 402; // (invalid_credintials) => 450;
      } else if (
        error.response.data.status == 401 ||
        error.response.data.status == 402 ||
        error.response.data.status == 450
      ) {
        this.$toast.error(error.response.data.message);
        // (unverified) => 406;
      } else if (error.response.data.status == 406) {
        this.$toast.error(error.response.data.message);
        this.$router.push({
          path: "/auth/verification",
        });
        // (frozen) => 403;
      } else if (error.response.data.status == 403) {
        //console.log(error.response.data)
        this.$toast.error(error.response.data.message);
        this.counterDown(error.response.data.data.expire_at);
      } else if (error.response.data.status == 452) {
        this.$toast.error(error.response.data.message);
      }

    },
    // 452 => don't have access
    getUserFinalForm(userForm) {
      let form = Object.assign({}, userForm);
      if (isEmail(userForm.email)) {
        form.type = "email";
      } else {
        form.type = "phone";
        form.phone = form.email;
        form.email = null;
      }
      return form;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);

    if (process.browser) {

      window.removeEventListener("resize", () => {
        this.windowWidth = window.innerWidth
      });
    }
  }


};

Vue.mixin(mixin);
